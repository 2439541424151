import { Helmet } from "react-helmet";
import { Titles } from "../../types/titles";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TExchangeItem, TTheme } from "../../types/types";
import CustomButton from "../common/button/custom-button";
import { useTelegram } from "../../hooks/useTelegram";
import ExchangeInput from "../common/input/exchange-input/exchange-input";
import SwapButton from "../../icons/swap-button";
import { ExchangeContext } from "../../data/exchange-provider";
import CircleProgress from "../circle-progress/circle-progress";
import { Whale } from "../../icons/whale";
import CustomInput from "../common/input/custom-input";
import { ThemeContext } from "../../data/theme-provider";
import OTCConfirmation from "./confirmation/otc-confirmation";
import { FirebaseContext } from "../../data/firebase-provider";
import "./otc.css";
import { initOTCPage, sendOTC, sendOTCEula } from "../../api/api";
import OTCSummary from "./summary/otc-summary";
import OTCEula from "./eula/otc-eula";

const locale = window.location.pathname.split("/")[1] != "en" ? "ru" : "en";

const OTC = () => {
	const { initializeFirebase } = useContext(FirebaseContext);
	const { updateTheme, theme } = useContext(ThemeContext);
	const { t } = useTranslation();
	const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
	const { tg } = useTelegram();
	const {
		data,
		isWalletsEmpty,
		isLoading,
		pairs,
		updateUserTokenOtc,
		eWallet,
		getOtcPairForSymbol,
		otcPairs,
	} = useContext(ExchangeContext);
	const [fakeData, setFakeData] = useState<TExchangeItem[]>([]);
	const [fakePairs, setFakePairs] = useState<TExchangeItem[]>([]);
	const [isInputError, setIsInputError] = useState<boolean>(false);
	const [selectedFromItem, setSelectedFromItem] = useState<TExchangeItem>();
	const [selectedToItem, setSelectedToItem] = useState<TExchangeItem>();
	const [isSwap, setIsSwap] = useState<boolean>(false);
	const [fromValue, setFromValue] = useState<string>("");
	const [toValue, setToValue] = useState<string>("");
	const [rate, setRate] = useState<string>("0");
	const [isRateRequired, setIsRateRequired] = useState<boolean>(true);
	const isConfirmButtonDisabled =
		!isInputError &&
		!(Number(fromValue) == 0 || fromValue === "") &&
		!(
			toValue === "NaN" ||
			toValue === "Infinity" ||
			toValue === "" ||
			Number(toValue) == 0
		) &&
		!(Number(rate) === 0);
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [errorText, setErrorText] = useState<string>("");
	const [isSummary, setIsSummary] = useState<boolean>(false);
	const [isEula, setIsEula] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [checkBoxes, setCheckBoxes] = useState<any>(undefined);
	const [isChecked, setIsChecked] = useState<boolean>(false);

	const handlePageInitialization = async () => {
		await initializeFirebase();
		await initOTCPage(
			sessionStorage.getItem("userToken") as string,
			locale,
			setIsEula,
			setCheckBoxes
		);
	};

	useEffect(() => {
		updateTheme();
		tg.expand();
		sessionStorage.setItem(
			"userToken",
			new URLSearchParams(window.location.search).get("token") as string
		);
		handlePageInitialization();
		updateUserTokenOtc(sessionStorage.getItem("userToken") as string, locale);
	}, []);

	useEffect(() => {
		if (!isSwap) {
			handleDataUpload();
		}
	}, [data]);

	const handleDataUpload = async () => {
		if (!isSwap) {
			if (data.length > 0) {
				const localFakeData = data.filter(
					(item) =>
						item.available > 0 &&
						otcPairs.find((pair) => pair.code === item.code)
				);
				setFakeData(localFakeData);
				await setSelectedFromItem(localFakeData[0]);
				await getOtcPairForSymbol(localFakeData[0]);
			}
		}
	};

	useEffect(() => {
		handlePairsUpload();
	}, [pairs, otcPairs]);

	const handlePairsUpload = async () => {
		if (!isSwap) {
			if (pairs.length > 0) {
				const fakePairs = pairs.filter(
					(asset: TExchangeItem) => asset.code != selectedFromItem?.code
				);
				setFakePairs(fakePairs);
				await setSelectedToItem(fakePairs[0]);
			}
		}
		if (isSwap) {
			if (pairs.length > 0) {
				setFakePairs(
					pairs.filter(
						(asset: TExchangeItem) => asset.code != selectedToItem?.code
					)
				);
			}
		}
	};

	const handleBackClickButton = () => {
		tg.close();
	};

	if (isWalletsEmpty) {
		return (
			<>
				<Helmet>
					<title>{Titles.EXCHANGE.EMPTY}</title>
				</Helmet>
				<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
					<div className={"innerComponent"}>
						<div className={"exchangePaymentLogoContainer"}>
							<Whale className={""} />
						</div>
						<h1
							className={
								"exchangePaymentHeaderText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeWelcomeText")}
						</h1>
						<h1
							className={
								"exchangePaymentBottomText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeWalletsEmptyText")}
						</h1>
						<CustomButton
							theme={theme as TTheme}
							onClick={handleBackClickButton}
							className={"exchangeConfirmCloseButton"}
							text={t("exchangeCloseButton") as string}
						/>
					</div>
				</div>
			</>
		);
	}

	if (
		isLoading ||
		data.length === 0 ||
		selectedFromItem == undefined ||
		pairs.length === 0 ||
		selectedToItem == undefined ||
		theme === ""
	)
		return <CircleProgress thickness={6} size={40} />;

	const handleSubmitModalOpen = async () => {
		await setIsConfirmation(true);
	};

	const handleFromValueChange = async (amount: string) => {
		const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,${selectedFromItem.precision}}$`);
		if (amount === "" || regex.test(amount)) {
			await setFromValue(amount);
			if (amount === "") {
				await setFromValue("");
				await setToValue("");
				await setRate("");
				return;
			}
		}
	};

	const handleToValueChange = async (amount: string) => {
		const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,${selectedToItem.precision}}$`);
		if (amount === "" || regex.test(amount)) {
			await setToValue(amount);
			if (amount === "") {
				await setToValue("");
				return;
			}
			if (
				selectedFromItem.code.concat(selectedToItem.code) ===
				selectedToItem.symbolName
			) {
				setRate(
					String(
						Number(Number(amount) * Number(fromValue)).toFixed(
							selectedToItem.precision
						)
					)
				);
			} else {
				setRate(
					String(
						Number(Number(fromValue) / Number(amount)).toFixed(
							selectedToItem.precision
						)
					)
				);
			}
		}
	};

	const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,${selectedToItem.precision < 5 ? 5 : selectedToItem.precision}}$`);
		if (e.target.value === "" || regex.test(e.target.value)) {
			setRate(e.target.value);
			setIsRateRequired(e.target.value.length > 0);
			if (
				selectedFromItem.code.concat(selectedToItem.code) ===
				selectedToItem.symbolName
			) {
				setToValue(
					String(
						Number(Number(e.target.value) * Number(fromValue)).toFixed(
							selectedToItem.precision < 5 ? 5 : selectedToItem.precision
						)
					)
				);
			} else {
				setToValue(
					String(
						Number(Number(fromValue) / Number(e.target.value)).toFixed(
							selectedToItem.precision < 5 ? 5 : selectedToItem.precision
						)
					)
				);
			}
		}
	};

	const handleRateClearClick = () => {
		setRate("0");
		setFromValue("");
		setToValue("");
		setIsInputError(false);
		setIsRateRequired(true);
	};

	const handleFromClick = async (i: TExchangeItem) => {
		await setFromValue("");
		await setToValue("");
		await setSelectedFromItem(i);
		await getOtcPairForSymbol(i);
	};

	const handleAvailableFromClick = (amount: string) => {
		handleFromValueChange(amount);
	};

	const handleToClick = async (i: TExchangeItem) => {
		await setSelectedToItem(i);
		setFakePairs(
			pairs.filter(
				(asset: TExchangeItem) =>
					asset.code != i.code && asset.code != selectedFromItem?.code
			)
		);
	};

	const handleSwapClick = async () => {
		setIsSwap(true);
		await setFromValue("");
		await setToValue("");
		const from = selectedFromItem;
		const to = selectedToItem;
		from.symbolName = to.symbolName;
		await setSelectedFromItem(to);
		await setSelectedToItem(from);
		setIsSwap(false);
	};

	const isSwapButtonDisabled = selectedToItem.available === 0;

	const handleSubmitConfirmation = async () => {
		await sendOTC(
			sessionStorage.getItem("userToken") as string,
			selectedFromItem.code.toUpperCase(),
			Number(fromValue),
			selectedToItem.code.toUpperCase(),
			Number(toValue),
			Number(rate),
			setIsError,
			setIsButtonLoading,
			setIsConfirmation,
			setIsSummary,
			setErrorText
		);
	};

	const handleSubmitEula = async () => {
		await sendOTCEula(
			sessionStorage.getItem("userToken") as string,
			checkBoxes,
			isChecked,
			setIsError,
			setIsButtonLoading,
			setIsEula
		);
	};

	if (isEula) {
		return (
			<>
				<Helmet>
					<title>{Titles.OTC.EULA}</title>
				</Helmet>
				<OTCEula
					checked={isChecked}
					setIsChecked={setIsChecked}
					checkBoxes={checkBoxes}
					isError={isError}
					handleSubmit={handleSubmitEula}
					isButtonLoading={isButtonLoading}
					locale={locale}
				/>
			</>
		);
	}

	if (isConfirmation) {
		return (
			<>
				<Helmet>
					<title>{Titles.OTC.CONFIRMATION}</title>
				</Helmet>
				<OTCConfirmation
					selectedFromItem={selectedFromItem}
					selectedToItem={selectedToItem}
					fromValue={fromValue}
					toValue={toValue}
					rate={rate}
					setIsSubmitModalOpen={() => setIsConfirmation(false)}
					handleSubmit={handleSubmitConfirmation}
					isLoading={isButtonLoading}
					isError={isError}
					errorText={errorText}
				/>
			</>
		);
	}

	if (isSummary) {
		return (
			<>
				<Helmet>
					<title>{Titles.OTC.SUMMARY}</title>
				</Helmet>
				<OTCSummary
					paymentInfo={{
						assetFrom: selectedFromItem,
						assetTo: selectedToItem,
						amountFrom: fromValue,
						amountTo: toValue,
						date: new Date().toUTCString(),
					}}
					locale={locale}
					rate={rate}
					eWallet={eWallet}
				/>
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>{Titles.OTC.INDEX}</title>
			</Helmet>
			<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
				<div className={"innerComponent"}>
					<h1
						className={
							"exchangeWelcomeText" + (theme === "dark" ? "-dark" : "")
						}
					>
						{t("OTCHeader")}
					</h1>
					<ExchangeInput
						isFrom={true}
						isInputError={isInputError}
						setIsInputError={setIsInputError}
						label={t("exchangeFromText")}
						amount={fromValue}
						setAmount={handleFromValueChange}
						available={selectedFromItem.available}
						asset={selectedFromItem}
						handleItemClick={handleFromClick}
						handleAvailableClick={handleAvailableFromClick}
						assets={fakeData}
						fontSize={fromValue.length > 8 ? "18px" : "24px"}
					/>
					<CustomButton
						theme={theme as TTheme}
						onClick={handleSwapClick}
						isDisabled={isSwapButtonDisabled}
						isSwapButton={true}
						className={"exchangeSwapContainer"}
					>
						<SwapButton className={"exchangeSwapIcon"} />
					</CustomButton>
					<ExchangeInput
						isFrom={false}
						isInputError={isInputError}
						setIsInputError={setIsInputError}
						label={t("exchangeToText")}
						amount={toValue}
						setAmount={handleToValueChange}
						available={selectedToItem.available}
						asset={selectedToItem}
						handleItemClick={handleToClick}
						assets={fakePairs}
						fontSize={toValue.length > 8 ? "18px" : "24px"}
						isOTC={true}
					/>
					<CustomInput
						placeHolder={"0"}
						isRequired={isRateRequired}
						type={"number"}
						isPattern
						id={"email"}
						label={t("OTCRate") as string}
						value={rate}
						onChange={handleRateChange}
						className={"rateInputContainer"}
						onClearClick={handleRateClearClick}
						theme={theme as TTheme}
					/>
					<div className={"exchangeRate"}>
						<h1
							className={
								"exchangeRateWarningText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{t("OTCWarningText")}
						</h1>
					</div>
					<CustomButton
						theme={theme as TTheme}
						onClick={handleSubmitModalOpen}
						className={"exchangeConfirmButton"}
						isDisabled={!isConfirmButtonDisabled}
						text={t("sendButtonText") as string}
					/>
					<CustomButton
						theme={theme as TTheme}
						isBack={true}
						onClick={handleBackClickButton}
						className={"backButton"}
					/>
				</div>
			</div>
		</>
	);
};

export default OTC;
